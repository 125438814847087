import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { toast } from 'react-toastify';
import styles from './FileDropzone.module.scss';
import contactFormStyles from '../ContactForm/ContactForm.module.scss';
import {useTranslation} from "react-i18next";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  borderWidth: 2,
  height: '5rem',
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#A3C7F6'
};

const acceptStyle = {
  borderColor: '#00e676',
  color: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

const FileDropzone = ({ onChange, disabled, parentRef }) => {
  const [filesForUpload, setFilesForUpload] = useState([]);
  const { t } = useTranslation();
  const bytesInMb = 1000000;
  const MAX_FILES = 5;
  const MAX_SIZE = 5 * bytesInMb;

  const removeFile = (file) => {
    const listForCleanup = [...filesForUpload];
    const index = listForCleanup.indexOf(file);
    if (index > -1) {
      listForCleanup.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFilesForUpload(listForCleanup);
  }

  const getFileParams = (file) => {
    return `${file.path}|${file.name}|${file.lastModified}|${file.size}`
  }

  const addFiles = (files) => {
    if ((filesForUpload.length + files.length) > MAX_FILES) {
      toast.error(t('error.maxFiles', {max: MAX_FILES}));
      return
    }

    files = files.reduce((acc, curr) => {
      if (filesForUpload.map(f => getFileParams(f)).includes(getFileParams(curr))) {
        toast.warning(t('error.fileAlreadyExists', {file: curr.name}))
        return acc
      }
      return [...acc, curr]
    }, [])

    if (filesForUpload.length < MAX_FILES) {
      setFilesForUpload(prev => ([...prev, ...files]))
    }
  }
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    disabled,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
      'application/rtf': ['.rtf'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    },
    maxFiles: MAX_FILES,
    maxSize: MAX_SIZE,
    onDropAccepted: (files) => {
      addFiles(files)
    },
    onDropRejected: (fileRejections) => {
      fileRejections.every(({file, errors}) => {
        if (errors.length && errors[0].code === 'file-invalid-type') {
          toast.error(t('error.invalidType', {file: file.name}))
          return;
        }
        if (errors.length && errors[0].code === 'file-too-large') {
          toast.error(t('error.fileTooLarge', {file: file.name, max: (MAX_SIZE / bytesInMb).toFixed()}))
          return;
        }
        if (errors.length && errors[0].code === 'too-many-files') {
          toast.error(t('error.maxFiles', {max: MAX_FILES}))
          return;
        }
        toast.error(t('error.failedToAddFile', {file: file.name}))
      })
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  useEffect(() => {
    onChange(filesForUpload);
  }, [filesForUpload]);

  React.useEffect(() => {
    parentRef.current = {
      reset: () => setFilesForUpload([])
    }
  }, [parentRef])

  return (
    <div className={styles.Dropzone}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} id="dropzoneInput" />
        {isDragAccept && (
          <p>{t('dropzone.accept')}</p>
        )}
        {isDragReject && (
          <p>{t('dropzone.reject')}</p>
        )}
        {(!isDragReject && !isDragAccept) && (
          <>
            <p>
              {t('dropzone.title')}<br />
              <small>{t('dropzone.subtitle', {
                maxFiles: MAX_FILES,
                maxSize: (MAX_SIZE / bytesInMb).toFixed()
              })}</small>
            </p>
          </>
        )}
      </div>

      {!!filesForUpload.length && (
        <div className={styles.DropzoneFileList}>
          <div className={contactFormStyles.formLabel}>{t('')}</div>
          {filesForUpload.map(file => (
            <div className={styles.DropzoneFile} key={file.path}>
              <div className={styles.DropzoneFileName}>{file.path}</div>
              <div className={styles.DropzoneFileSize}>{(file.size / bytesInMb).toFixed(1)}Mb</div>
              <a
                role="button"
                title={t('dropzone.remove')}
                className={styles.DropzoneFileRemove}
                onClick={() => removeFile(file)}>
                &times;
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileDropzone;
