import {object, string, bool} from 'yup';
import i18n from "../i18n";

export const getContactValidation = (locale) => {
  const { t } = i18n;
  const tOptions = { lng: locale };
  return object({
    name: string().trim()
      .required(t('error.name.required', tOptions))
      .max(150, t('error.name.max', {...tOptions, max: 150})),
    email: string().trim()
      .email(t('error.email.invalid', tOptions))
      .max(150, t('error.email.max', {...tOptions, max: 150}))
      .required(t('error.email.required', tOptions)),
    phone: string().trim()
      .required(t('error.phone.required', tOptions))
      .matches(/^[\d\s\-()+]+$/g, {
        message: t('error.phone.match'),
        excludeEmptyString: true
      })
      .max(20),
    message: string().trim()
      .max(1000, t('error.message.max', { ...tOptions, max: 1000 }))
      .required(t('error.message.required', tOptions)),
    // captcha: string()
    //   .required(t('error.captcha.required', tOptions)),
    terms: bool()
      .required(t('error.terms.required', tOptions))
      .oneOf([true], t('error.terms.required', tOptions)),
  });
}
